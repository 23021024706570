a {
  text-decoration: none;
}

html{
  background-color: #f3d5b5;
}

.App {
  min-height: 100vh;
}

.detail-container textarea{
  resize: none;
}

.detail-upper{
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: auto;
  margin-top: 10px;
}

.container-list {
  gap: 30px;
  margin: 10px;
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.container-list img {
  border-radius: 10px;
  transition: transform 200ms ease-in;
}

/* .container-list img:hover {
  transform: scale(1.05);
} */

.navbar {
  background-color: #a47148;
  height: 45px;
  padding: 5px;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #e9c46a;
}
.navbar span {
  text-transform:capitalize;
}

.navbar a {
  color: #e9c46a;
}

.nav-item-right {
  display: flex;
  gap: 10px;
  margin-right: 10px;
}

.nav-item-left {
  margin-left: 10px;
  font-size: 25px;
}

.navbar input {
  outline: none;
  border: none;
  border-radius: 10px;
  height: 35px;
  width: 300px;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #3e3e3e;
  padding-left: 10px;
}

.search-bar {
  display: flex;
  gap: 10px;
  align-items: center;
}

.flip-card {
  background-color: transparent;
  width: 265px;
  height: 400px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card img {
  border-radius: 10px;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  padding-left: 15px;
  border-radius: 10px;
  background-color: #bc8a5f;
  color: #583101;
  transform: rotateY(180deg);
  font-size: 18px;
}




.login-container {
  width: 25%;
  background: #a47148;
  height: 70vh;
  margin: 70px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.login-container form {
  width: 75%;
}

.login-container input {
    display: block;
    border: 2px solid #52154e;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 25px;
    padding: 5px;
    width: 100%;
}


.login-container button {
  margin-left: 90px;
  margin-bottom: 75px;
  margin-top: 10px;
  background-color: #52154e; 
  border: none;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: white; 
  color: #52154e;

}


.login-container img {
  margin-left: 50px;
}

.registerU{
  height: 80vh;
}

.registerU button {
  margin-bottom: 30px;

}

.comments-container{
  border: 3px solid #a47148;
  border-radius: 10px;
  width: 30%;
  margin: auto;
  padding: 5px;
}

.comments{
display: flex;
flex-direction: column;
margin-bottom: 5px;
font-size: 18px;
}

.comment-time{
  margin-left: 5px;
  color: #583101;
}
.comment-item{
  color: #583101;

}

.comment-info{
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  margin-top: 3px;
  
}

.comment-area{
  width: 30%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.comment-area textarea{
  background-color: #f3d5b5;
  box-shadow: 5px 5px #a47148;
  border: 3px solid #a47148;
  border-radius: 10px;
  padding: 10px;
  margin-top: 8px;
  color: #a47148;
  font-size: 20px;

}

.comment-area textarea:focus{
  outline: none !important;
  border-color: #a47148;
  
  }

.comment-area button {
  align-self: flex-end;
  width: 15%;
  margin: 12px 12px 8px 0px;
  padding: 10px;
  background-color: #583101; 
  border: none;
  border-radius: 4px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  
}

.btn-container{
  display: flex;
}

.like-btn {
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.delete-btn:hover{
transform: scale(1.1);
cursor: pointer;
}